import ReclaimItIframe from '@activebrands/core-web/components/ReclaimItIframe';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

interface ReclaimItProps {
    src: string;
}
const ReclaimIt = ({ src = '' }: ReclaimItProps) => {
    const [css] = useStyletron();

    return (
        <div
            className={css({
                margin: 'var(--margin-content)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            })}
        >
            <ReclaimItIframe src={src} height="800px" width="615px" />
        </div>
    );
};

export default ReclaimIt;
